import React, {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import Cookies from 'js-cookie';
import post from './utils/post.js';
import $ from 'jquery';

import './styles/fonts.scss';
import './styles/index.scss';

import Login from './pages/Login.js';
import Forget from './pages/Forget.js';
import Restore from './pages/Restore.js';
import Register from './pages/Register.js';
import Activate from './pages/Activate.js';
import Home from './pages/Home.js';
import User from './pages/User.js';
import UserLog from './pages/UserLog.js';
import Member from './pages/Member.js';
import Trans from './pages/Trans.js';
import NotFound from './pages/NotFound.js';
import Payment from './pages/Payment.js';
import EshopSysadmin from './pages/EshopSysadmin.js';
import Exercise from './pages/Exercise.js';
import Eshop from './pages/Eshop.js';
import Pay from './pages/Pay.js';
import Setting from './pages/Setting.js';
import EshopItem from './pages/EshopItem.js';
import MyPayment from './pages/MyPayment.js';
import Note from './pages/Note.js';
import Message from './pages/Message.js';
import MessageSysadmin from './pages/MessageSysadmin.js';
import WorkoutOffic from './pages/WorkoutOffic.js';
import WorkoutMax from './pages/WorkoutMax.js';
import Musclegroup from './pages/Musclegroup.js';
import Workout from './pages/Workout.js';
import Stat from './pages/Stat.js';
import Timer from './pages/Timer.js';
import Keys from './pages/Keys.js';
import RezervationRecs from './pages/RezervationRecs.js';

import notificationSound from './audio1.wav';
const audio = new Audio(notificationSound);

const playAnnRef = React.createRef();

const mapStateToProps = (state) => {
	return state;
};

function App(props) {
	const [loading, setLoading] = useState(true);
	const [usrInfo, setUsrInfo] = useState({usrId: 0});
	const [timerCntId, setTimerCntId] = useState(null);
	const [timerCnt, setTimerCnt] = useState({});

	useEffect(() => {
		if (Cookies.get('progressgym')) {
			getInitData();
			checkTransCnt();
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const startTimer = () => {
		if (timerCntId) {
			clearTimeout(timerCntId);
		}

		const newTimerId = setTimeout(() => {
			checkTransCnt();
		}, 30000);

		setTimerCntId(newTimerId);
	};

	const checkTransCnt = () => {
		post('getTimerData', {})
			.then((res) => {
				startTimer();

				let cM = parseInt(res.data.data_msg_cnt) || 0;
				let cT = parseInt(res.data.data_trans_cnt) || 0;
				let cE = parseInt(res.data.data_todayeshop_cnt) || 0;

				let mV = parseInt($('#msgCnt').val());
				let tV = parseInt($('#trsCnt').val());
				let eV = parseInt($('#espCnt').val());

				if (cM > mV || cT > tV || cE > eV) {
					playAnnRef.current.click();
				}

				$('#msgCnt').val(cM);
				$('#trsCnt').val(cT);
				$('#espCnt').val(cE);

				setTimerCnt(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getInitData = () => {
		setLoading(true);

		post('getInitData')
			.then((res) => {
				setLoading(false);
				setUsrInfo(res.usrInfo);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleUserMenuClick = (e) => {
		if (e.target.id === 'iconmenu') {
			if ($('.usermenu').is(':visible')) {
				$('.usermenu').slideUp(100);
			} else {
				$('.usermenu').slideDown(100);
			}
		} else {
			$('.usermenu').slideUp(100);
		}
	};

	const playAnn = () => {
		audio.play();
	};

	useEffect(() => {
		document.addEventListener('click', handleUserMenuClick);

		return () => {
			document.removeEventListener('click', handleUserMenuClick);
		};
	}, []);

	if (loading) {
		return <div className="loading" />;
	}

	return (
		<div className={`app ${!Cookies.get('progressgym_theme') ? 'dark_theme' : 'light_theme'}`} id="app">
			{loading ? <div className="loading" /> : ''}

			<button id="playAnnID" ref={playAnnRef} onClick={() => playAnn()} className="hidden"></button>
			<input id="msgCnt" type="hidden" />
			<input id="trsCnt" type="hidden" />
			<input id="espCnt" type="hidden" />

			{Cookies.get('progressgym') ? (
				<>
					<Routes>
						{usrInfo.CLUBMEMBER === 1 ? (
							<>
								<Route exact path="/" element={<Home usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/home" element={<Home usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/login" element={<Home usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/eshop" element={<Eshop usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/pay" element={<Pay usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/mypayment" element={<MyPayment usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/message" element={<Message usrInfo={usrInfo} timerCnt={timerCnt} />} />
							</>
						) : (
							<>
								<Route exact path="/" element={<Exercise usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/home" element={<Exercise usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/login" element={<Exercise usrInfo={usrInfo} timerCnt={timerCnt} />} />
							</>
						)}

						<Route exact path="/exercise" element={<Exercise usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/workoutmax" element={<WorkoutMax usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/timer" element={<Timer usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/workout" element={<Workout usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/musclegroup" element={<Musclegroup usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/stat" element={<Stat usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/note" element={<Note usrInfo={usrInfo} timerCnt={timerCnt} />} />
						<Route exact path="/setting" element={<Setting usrInfo={usrInfo} timerCnt={timerCnt} />} />

						{usrInfo.usrId === 1 || usrInfo.usrId === 4 ? (
							<>
								<Route exact path="/member" element={<Member usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/member/:directSearch" element={<Member usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/trans" element={<Trans usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/payment" element={<Payment usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/payment/:directSearch" element={<Payment usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/eshopsysadmin" element={<EshopSysadmin usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/eshopsysadmin/:directSearch" element={<EshopSysadmin usrInfo={usrInfo} timerCnt={timerCnt} />} />
							</>
						) : (
							<></>
						)}

						{usrInfo.usrId === 1 ? (
							<>
								<Route exact path="/user" element={<User usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/user/:directSearch" element={<User usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/keys" element={<Keys usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/messagesysadmin" element={<MessageSysadmin usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/userlog" element={<UserLog usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/userlog/:directSearch" element={<UserLog usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/eshopitem" element={<EshopItem usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/workoutoffic" element={<WorkoutOffic usrInfo={usrInfo} timerCnt={timerCnt} />} />
								<Route exact path="/rezervationrecs" element={<RezervationRecs usrInfo={usrInfo} timerCnt={timerCnt} />} />
							</>
						) : (
							<></>
						)}

						<Route exact path="*" element={<NotFound />} />
					</Routes>
				</>
			) : (
				<>
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/forget" element={<Forget />} />
						<Route exact path="/forgetr" element={<Restore />} />
						<Route exact path="/register" element={<Register />} />
						<Route exact path="/activate" element={<Activate />} />
						<Route exact path="*" element={<NotFound />} />
					</Routes>
				</>
			)}
		</div>
	);
}

export default mapStateToProps(App);
