import React, {useState, useRef, useEffect} from 'react';
import post from '../utils/post.js';
import AppHeader from '../components/AppHeader.js';
import Search from '../components/Search.js';
import PaymentNew from '../components/PaymentNew.js';
import PaymentEdit from '../components/PaymentEdit.js';
import ChartColumn from '../components/ChartColumn.js';
import Cookies from 'js-cookie';
import FormSelect from '../components/FormSelect.js';

export default function Payment({usrInfo, timerCnt}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataUser, setDataUser] = useState([]);
	const [PAYMENT_CHCK1, setPAYMENT_CHCK1] = useState(1);
	const [PAYMENT_CHCK2, setPAYMENT_CHCK2] = useState(1);
	const [report_pdf, setReport_pdf] = useState('');
	const [data_pluss, setData_pluss] = useState(0);
	const [data_minuss, setData_minuss] = useState(0);
	const [data_kasa, setData_kasa] = useState(0);
	const [data_kasas, setData_kasas] = useState(0);
	const [data_graph_plus, setData_graph_plus] = useState([]);
	const [data_graph_minus, setData_graph_minus] = useState([]);
	const [data_grapt_labels, setData_grapt_labels] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [loadedCnt, setLoadedCnt] = useState(0);
	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [detailData, setDetailData] = useState([]);
	const [dataNew, setDataNew] = useState([]);
	const [months, setMonths] = useState(24);
	const [monthsName, setMonthsName] = useState('graf 24 měsíců');
	const [monthsOptions, setMonthsOptions] = useState([]);

	const monthsRef = useRef(null);

	useEffect(() => {
		let search = '';

		if (typeof window.location.pathname.split('/')[2] != 'undefined') {
			search = decodeURI(window.location.pathname.split('/')[2]);
			setSearchValue(search);
		}

		getData(search, offset, months);

		if (search !== '') {
			window.history.pushState({}, document.title, '/payment');
		}

		setMonthsOptions([
			{ID: 48, NAME: 'graf 48 měsíců'},
			{ID: 36, NAME: 'graf 36 měsíců'},
			{ID: 24, NAME: 'graf 24 měsíců'},
			{ID: 12, NAME: 'graf 12 měsíců'},
			{ID: 6, NAME: 'graf 6 měsíců'},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeSearch = (value) => {
		setSearchValue(value);
		setOffset(0);
		getData(value, 0, months);
	};

	const loadNext = () => {
		setOffset((prevOffset) => {
			let newOffset = prevOffset + usrInfo.LIST_ROWS;
			getData(searchValue, prevOffset + usrInfo.LIST_ROWS, months);
			return newOffset;
		});
	};

	const getData = (search, offset, months) => {
		setLoading(true);

		post('getListPayment', {search: search, offset: offset, months: months})
			.then((res) => {
				setPAYMENT_CHCK1(res.data.PAYMENT_CHCK1);
				setPAYMENT_CHCK2(res.data.PAYMENT_CHCK2);
				setDataUser(res.data.data_user);
				setReport_pdf(res.data.report_pdf);
				setData_pluss(res.data.data_pluss);
				setData_minuss(res.data.data_minuss);
				setData_kasa(res.data.data_kasa);
				setData_kasas(res.data.data_kasas);
				setData_graph_plus(res.data.data_graph_plus);
				setData_graph_minus(res.data.data_graph_minus);
				setData_grapt_labels(res.data.data_graph_labels);

				if (offset === 0) {
					setData(res.data.data);
				} else {
					setData((prevRows) => {
						const updatedRows = [...data, ...res.data.data];
						return updatedRows;
					});
				}

				setLoadedCnt(res.data.data.length);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const saveFltr = (fltr) => {
		let paymentChecks = {
			PAYMENT_CHCK1: PAYMENT_CHCK1,
			PAYMENT_CHCK2: PAYMENT_CHCK2,
		};

		let fltrvar = 'PAYMENT_CHCK' + fltr;
		let fltrval = paymentChecks[fltrvar];

		if (parseInt(fltrval) === 1) {
			fltrval = 0;
		} else {
			fltrval = 1;
		}

		if (fltr === 1) {
			setPAYMENT_CHCK1(fltrval);
		} else {
			setPAYMENT_CHCK2(fltrval);
		}

		setLoading(true);

		post('savePaymentFltr', {search: searchValue, offset: 0, fltr: fltrvar, fltrval: fltrval, months: months})
			.then((res) => {
				setLoading(false);
				setOffset(0);
				setData_pluss(res.data.data_pluss);
				setData_minuss(res.data.data_minuss);
				setData_kasa(res.data.data_kasa);
				setData_kasas(res.data.data_kasas);
				setData(res.data.data);
				setData_graph_plus(res.data.data_graph_plus);
				setData_graph_minus(res.data.data_graph_minus);
				setData_grapt_labels(res.data.data_graph_labels);
				setLoadedCnt(res.data.data.length);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const selectedColumn = (type, value, label) => {
		setSearchValue(label);
		getData(label, 0, months);
	};

	const handleNew = () => {
		if (usrInfo.usrId === 1) {
			setDataNew({});
			setShowNew(true);
		}
	};

	const closeNew = () => {
		setShowNew(false);
	};

	const updateRow = (res) => {
		//setSearchValue('');
		setOffset(0);
		setMonths(24);
		setMonthsName('graf 24 měsíců');

		setData_pluss(res.data_pluss);
		setData_minuss(res.data_minuss);
		setData_kasa(res.data_kasa);
		setData_kasas(res.data_kasas);
		setData(res.data);
		setData_graph_plus(res.data_graph_plus);
		setData_graph_minus(res.data_graph_minus);
		setData_grapt_labels(res.data_graph_labels);
		setLoadedCnt(res.data.length);
	};

	const handleEdit = (item) => {
		if (usrInfo.usrId === 1) {
			setDetailData({item: item, user: dataUser});
			setShowEdit(true);
		}
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const copyToNewPay = () => {
		let nData = {
			usrId: detailData.item.USRID,
			//paymentDT: detailData.item.PAYMENTDTS,
			note: detailData.item.NOTE,
			amount: detailData.item.AMOUNT,
		};

		setDataNew(nData);
		setShowNew(true);
	};

	const setMonth = (val) => {
		setMonths(val.ID);
		setMonthsName(val.NAME);
		getData(searchValue, 0, val.ID);
	};

	const list = data?.map((item, index) => {
		return (
			<div className={`list ${usrInfo.usrId === 1 ? 'cr_p' : ''}`} key={'item_' + index} onClick={() => handleEdit(item)}>
				<div className="list_in">
					<div className="fl_l txt_l">
						{item.PAYMENTDTS}
						{item.USRNAME !== '' ? <span> - {item.USRNAME}</span> : ''}
						{item.NOTE !== '' ? <span className="fnt_gray fnt_11"> - {item.NOTE}</span> : ''}
					</div>

					<div className={`fl_r txt_r ${parseInt(item.TYPEID) === 2 ? 'fnt_orange' : 'fnt_green'}`}>
						{parseInt(item.TYPEID) === 2 ? '-' : ''}
						{item.AMOUNTS}
					</div>
					<div className="zero h0" />
				</div>
			</div>
		);
	});

	return (
		<>
			<div className="page_main">
				<AppHeader usrInfo={usrInfo} timerCnt={timerCnt} loading={loading} />
				<Search showSearch searchValue={searchValue} onChangeSearch={(e) => onChangeSearch(e)} showNew={usrInfo.usrId === 1 ? true : false} handleNew={handleNew} />
				<PaymentNew showNew={showNew} closeNew={closeNew} updateRow={updateRow} dataNew={dataNew} dataUser={dataUser} />
				<PaymentEdit showEdit={showEdit} closeEdit={closeEdit} updateRow={updateRow} data={detailData} copyToNewPay={copyToNewPay} searchValue={searchValue} />

				<div className={`page ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''}`}>
					<div className="" style={{height: '23rem'}}>
						<ChartColumn data1={data_graph_plus} data2={data_graph_minus} labels={data_grapt_labels} stepSize={parseInt(PAYMENT_CHCK2) === 0 ? 5000 : 0} linesColor={Cookies.get('progressgym_theme') ? '#555555' : '#dddddd'} selectedColumn={selectedColumn} />
						<div className="zero h0" />
					</div>
					<div className="zero h0" />

					<div className="fl_l mr_2 cr_p mt_05" onClick={() => saveFltr(1)}>
						<button className={`button check ${PAYMENT_CHCK1 === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
						<span className="fl_l">Příjmy</span>
					</div>
					<div className="fl_l mr_2 cr_p mt_05" onClick={() => saveFltr(2)}>
						<button className={`button check ${PAYMENT_CHCK2 === 1 ? 'green' : ''} fl_l mr_05`}>&nbsp;</button>
						<span className="fl_l">Výdaje</span>
					</div>

					<div className="fl_l mr_2" style={{width: '12rem'}}>
						<FormSelect type="text" label="" value={months} valueLabel={monthsName} setValue={setMonth} options={monthsOptions} showNone={false} ref={monthsRef} error="" />
					</div>

					<div className="fl_r cr_p mt_03" onClick={() => window.open(report_pdf + '&search=' + searchValue, '_blank')}>
						<img className="icon1 pdf2" alt="" />
						<span className="ml_1 fnt_12">PDF</span>
					</div>
					<div className="zero h2" />

					<div className="list" key={'item_plus'}>
						<div className="list_in">
							<div className="fl_l txt_l">Příjmy celkem:</div>
							<div className="fl_r txt_r fnt_green">{data_pluss}</div>
							<div className="zero h0" />
						</div>
					</div>
					<div className="list" key={'item_minus'}>
						<div className="list_in">
							<div className="fl_l txt_l">Výdaje celkem:</div>
							<div className="fl_r txt_r fnt_orange">{data_minuss}</div>
							<div className="zero h0" />
						</div>
					</div>
					<div className="list" key={'item_kasa'}>
						<div className="list_in fnt_16">
							<b>
								<div className="fl_l txt_l">KASA:</div>
								<div className={`fl_r txt_r ${data_kasa >= 0 ? 'fnt_green' : 'fnt_orange'}`}>{data_kasas}</div>
							</b>
							<div className="zero h0" />
						</div>
					</div>
					<div className="zero h1" />

					{list}

					{parseInt(loadedCnt) === parseInt(usrInfo.LIST_ROWS) ? (
						<button className="button small loadNext" onClick={() => loadNext()}>
							Načíst další
						</button>
					) : (
						<span className="fnt_12 loadNext">Byly načteny všechny záznamy</span>
					)}
				</div>
			</div>
		</>
	);
}
