import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import post from '../utils/post.js';
import clipboard from '../utils/clipboard.js';
import Cookies from 'js-cookie';

export default function AppHeader({usrInfo, timerCnt, loading}) {
	useEffect(() => {
		let cM = parseInt(timerCnt.data_msg_cnt) || 0;
		let cT = parseInt(timerCnt.data_trans_cnt) || 0;
		let cE = parseInt(timerCnt.data_todayeshop_cnt) || 0;

		let titl = ' (';

		if (cM > 0) {
			if (titl !== ' (') {
				titl += ',';
			}
			titl += cM;
		}

		if (usrInfo.usrId === 1 || usrInfo.usrId === 4) {
			if (cT > 0) {
				if (titl !== ' (') {
					titl += ',';
				}
				titl += cT;
			}

			if (cE > 0) {
				if (titl !== ' (') {
					titl += ',';
				}
				titl += cE;
			}
		}

		if (titl !== ' (') {
			titl += ')';
		} else {
			titl = '';
		}

		document.title = 'Progress Gym' + titl;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timerCnt]);

	const logout = () => {
		post('logout', {})
			.then((res) => {
				if (res.usrId > -1) {
					window.location.pathname = '/';
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const changeTheme = () => {
		if (Cookies.get('progressgym_theme')) {
			Cookies.remove('progressgym_theme');
		} else {
			Cookies.set('progressgym_theme', '1');
		}

		window.location.reload();
	};

	return (
		<div className={`header ${usrInfo.usrId === 1 || usrInfo.usrId === 4 ? ' more' : ''} pos_r`}>
			<img className="icon_theme fl_l cr_p" onClick={() => changeTheme()} alt="" />
			{loading ? <div className="loading" /> : ''}

			<div className="icon menu fl_r ml_15" id="iconmenu">
				<div className="usermenu hidden">
					<div className="usermenu_email" onClick={() => clipboard(usrInfo.EMAIL)}>
						{usrInfo.EMAIL}
					</div>

					{usrInfo.DOORKEY !== '' ? (
						<>
							<div className="usermenu_doorkey fnt_green" onClick={() => clipboard(usrInfo.DOORKEY)}>
								Klíč: {usrInfo.DOORKEY}
							</div>
						</>
					) : (
						<>
							<div className="usermenu_doorkey fnt_orange">Klíč: žádný</div>
						</>
					)}

					{usrInfo.CLUBMEMBER === 1 ? (
						<Link to={'/'} key="lnk_home_usr" className="usermenu_item home">
							Rezervace
						</Link>
					) : (
						<></>
					)}

					{usrInfo.CLUBMEMBER === 1 ? (
						<>
							<Link to={'/eshop'} key="lnk_eshop_usr" className="usermenu_item eshop">
								Moje nákupy
							</Link>
							<Link to={'/mypayment'} key="lnk_pay_usr" className="usermenu_item permanent">
								Moje platby
							</Link>
							<Link to={'/message'} key="lnk_message_usr" className="usermenu_item email">
								Zprávy
							</Link>
						</>
					) : (
						<></>
					)}

					<Link to={'/exercise'} key="lnk_exercise_usr" className="usermenu_item exercise">
						Výkony
					</Link>

					<Link to={'/workoutmax'} key="lnk_workoutmax_usr" className="usermenu_item workoutmax">
						Maximálky
					</Link>

					<Link to={'/timer'} key="lnk_timer_usr" className="usermenu_item timer">
						Časovač
					</Link>

					<Link to={'/workout'} key="lnk_workout_usr" className="usermenu_item workout">
						Cviky
					</Link>

					<Link to={'/musclegroup'} key="lnk_musclegroup_usr" className="usermenu_item musclegroup">
						Svalové partie
					</Link>

					<Link to={'/stat'} key="lnk_stat_usr" className="usermenu_item stat">
						Statistiky a grafy
					</Link>

					<Link to={'/note'} key="lnk_note_usr" className="usermenu_item note">
						Poznámky
					</Link>

					<Link to={'/setting'} key="lnk_setting_usr" className="usermenu_item setting">
						Nastavení
					</Link>

					{usrInfo.usrId === 1 ? (
						<>
							<Link to={'/rezervationrecs'} key="lnk_trans_usr" className="usermenu_item keys">
								Záznamy rezervací
							</Link>
						</>
					) : (
						<></>
					)}

					{usrInfo.usrId === 1 || usrInfo.usrId === 4 ? (
						<>
							<Link to={'/trans'} key="lnk_trans_usr" className="usermenu_item trans">
								Transakce
							</Link>
							<Link to={'/payment'} key="lnk_payment_usr" className="usermenu_item payment">
								Platby
							</Link>
							<Link to={'/eshopsysadmin'} key="lnk_eshop_sysadmin_usr" className="usermenu_item eshop_sysadmin">
								Nákupy
							</Link>
							<Link to={'/member'} key="lnk_member_usr" className="usermenu_item member">
								Členové
							</Link>
						</>
					) : (
						<></>
					)}

					{usrInfo.usrId === 1 ? (
						<>
							<Link to={'/user'} key="lnk_user_usr" className="usermenu_item user">
								Uživatelé
							</Link>
							<Link to={'/keys'} key="lnk_key_usr" className="usermenu_item keys">
								Klíče
							</Link>
							<Link to={'/messagesysadmin'} key="lnk_messagesysadmin_usr" className="usermenu_item messagesysadmin">
								Zprávy
							</Link>
							<Link to={'/userlog'} key="lnk_log_usr" className="usermenu_item userlog">
								Logy
							</Link>
							<Link to={'/eshopitem'} key="lnk_eshopitem_usr" className="usermenu_item eshopitem">
								Zboží
							</Link>
							<Link to={'/workoutoffic'} key="lnk_workoutoffic_usr" className="usermenu_item worknoutsysadmin">
								Oficiální cviky
							</Link>
						</>
					) : (
						<></>
					)}

					<div className="usermenu_item logout" onClick={logout}>
						Odhlásit
					</div>
				</div>
			</div>

			{usrInfo.CLUBMEMBER === 1 ? (
				<>
					<Link to={'/message'} key="lnk_message">
						<div className="icon email fl_r ml_15 txt_c" style={{backgroundSize: '1.7rem'}}>
							{usrInfo.usrId === 1 && timerCnt.data_msg_cnt > 0 ? (
								<span className="fnt_message fnt_13 pos_r" style={{top: '1.6rem'}}>
									<b>{timerCnt.data_msg_cnt}</b>
								</span>
							) : (
								''
							)}
						</div>
					</Link>

					<Link to={'/pay'} key="lnk_pay">
						<div className="icon pay fl_r ml_15" />
					</Link>

					<Link to={'/eshop'} key="lnk_eshop">
						<div className="icon eshop fl_r ml_15" />
					</Link>
				</>
			) : (
				<></>
			)}

			<Link to={'/exercise'} key="lnk_exercise">
				<div className="icon exercise fl_r ml_15" />
			</Link>

			{usrInfo.CLUBMEMBER === 1 ? (
				<>
					<Link to={'/'} key="lnk_home">
						<div className={`icon ${usrInfo.DOORKEY !== '' ? 'keyon' : 'key'} fl_r ml_15`} />
					</Link>
				</>
			) : (
				<></>
			)}

			<div className="zero h1" />

			{usrInfo.usrId === 1 ? (
				<>
					<Link to={'/user'} key="lnk_user">
						<div className="icon user fl_r ml_15" />
					</Link>
				</>
			) : (
				<></>
			)}

			{usrInfo.usrId === 1 || usrInfo.usrId === 4 ? (
				<>
					<Link to={'/member'} key="lnk_member">
						<div className="icon member fl_r ml_15" />
					</Link>

					<Link to={'/eshopsysadmin'} key="lnk_eshop_sysadmin">
						<div className="icon eshop_sysadmin fl_r ml_15 txt_c">
							{usrInfo.usrId === 1 && timerCnt.data_todayeshop_cnt > 0 ? (
								<span className="fnt_message fnt_13 pos_r" style={{top: '1.6rem'}}>
									<b>{timerCnt.data_todayeshop_cnt}</b>
								</span>
							) : (
								''
							)}
						</div>
					</Link>

					<Link to={'/payment'} key="lnk_payment">
						<div className="icon payment_sysadmin fl_r ml_15" />
					</Link>

					<Link to={'/trans'} key="lnk_trans">
						<div className="icon trans_sysadmin fl_r ml_15 txt_c">
							{usrInfo.usrId === 1 && timerCnt.data_trans_cnt > 0 ? (
								<span className="fnt_message fnt_13 pos_r" style={{top: '1.6rem'}}>
									<b>{timerCnt.data_trans_cnt}</b>
								</span>
							) : (
								''
							)}
						</div>
					</Link>
				</>
			) : (
				<></>
			)}

			{usrInfo.usrId === 1 ? (
				<>
					<Link to={'/rezervationrecs'} key="lnk_reservationrecs">
						<div className="icon key2 fl_r ml_15" />
					</Link>
				</>
			) : (
				<></>
			)}
		</div>
	);
}
